export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    businessUnit: other.businessUnit || null,
    clientId: other.clientId || null,
    orgId: other.orgId || null,
    type: other.type || null,
    status: other.status || null,
    info: other.info || null,
    registrationTime: other.registrationTime || null,
    operationTime: other.operationTime || null,
  }
}
