<template>
  <b-modal no-fade v-model="dialog.show" size="xl" :id="`modal-kycs-${uniqueId}`"
           :hide-footer="true" centered @close="closeModal"
           no-close-on-backdrop
  >
    <template #modal-title>
     <span v-if="loading !== true && error == null && kycs != null">
       {{ kycs.firstName }}  {{ kycs.lastName }}
     </span>
    </template>
    <div style="min-height: 600px">
      <div v-if="loading !== true && error == null">
        <b-tabs  v-model="dialog.tab" content-class="" align="center">
          <b-tab :title="$t('tabs.kycs.details')" active>
<!--            <kycs-edit-details v-bind:new-kycs="isNewKycs" @submitted="onSubmitted" v-model="kycs"/>
       -->   </b-tab>

        </b-tabs>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%"
           v-else-if="loading !== true && error != null"
      >
        <b-alert show variant="danger">
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{ $t('operations.loading') }}</b-spinner>
      </div>
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions } from 'vuex';

export default {
  name: 'kycsModal',
  components: {  },
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
      tab: 0,

    },
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewKycs: false,
    kycs: {},
  }),

  watch: {
    queryParams: {
      handler() {
        if (this.queryParams.kycsId == null) {
          this.closeModal();
          return;
        }
        if (this.queryParams.kycsId !== this.id) {
          this.showModal(this.queryParams.kycsId);
        }
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid;
    if (this.queryParams.kycsId != null) {
      this.showModal(this.queryParams.kycsId);
    }
  },
  methods: {
    ...mapActions('kyc', ['getKycById']),
    showModal(id) {
      this.id = id;
      this.loading = true;
      this.dialog.show = true;
      this.dialog.tab = 0;
      this.isNewKycs = this.id == null || this.id === 0;
      if (!this.isNewKycs) {
        this.refreshData();
      } else {
        this.kycs = Kycs();
        this.error = null;
        this.loading = false;
      }
      this.pushState({
        kycsId: id,
        tab: 0,
      });
    },
    closeModal() {
      this.dialog.show = false;
      this.kycs = {};
      this.onDialogClose();
    },
    onDialogClose() {
      this.id = null;
      this.pushState({
        kycsId: null,
        tab: null,
      });
    },
    refreshData() {
      let $this = this;
      this.getKycById(this.id)
          .then(kycs => {
            $this.kycs = kycs;
            $this.error = null;
            $this.loading = false;
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
          });
    },
    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refreshData()
      this.dialog.tab = 0;
    }
  }
};
</script>

<style scoped>

</style>
