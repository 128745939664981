<template>
  <div>
    <!--Breadcrumb button-->
    <breadcrumb-button>
      <template #items>
        <b-dropdown-item @click="openColumnsModal">
          <feather-icon
              icon="CheckSquareIcon"
              size="16"
          />
          <span class="align-middle ml-50">{{ $t('columns.self') }}</span>
        </b-dropdown-item>
      </template>

    </breadcrumb-button>
    <!--Filters-->
    <filter-card v-model="filter" v-bind:fields="filterFields" search/>
    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t('menu.kycs') }}</h5>
      </b-card-header>
      <b-card-body>
        <kyc-table ref="kycTable" v-bind:filters="filter" v-bind:columns="columns" :has-selection="false"
                        @selected="onKycSelected" :lazy="true"/>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <columns-modal ref="columns-modal" column-key="kyc" v-model="columns"
                   v-bind:columns-names="columnsNames"
    />
    <kyc-modal ref="kyc-modal"/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import KycSearchRequest from '@/model/kyc/KycSearchRequest';
import ColumnsModal from '@/components/modal/columnsModal';
import Kyc from '@/model/kyc/Kyc';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinBase from '@/components/mixin/mixinBase';
import KycModal from '@/components/modal/kycModal';
import FilterCard from '@/components/widget/FilterCard';
import KycFilterFields from '@/model/filter/KycFilterFields';
import KycTable from '@/components/tables/KycTable';

export default {
  name: 'KycView',
  components: {
    KycTable,
    FilterCard,
    KycModal,
    ColumnsModal,
    BreadcrumbButton,
  },
  mixins: [mixinBase, mixinPermissions],
  data() {
    return {
      columnsNames: Object.keys(Kyc()),
      filter: KycSearchRequest(),
      filterLoaded: false,
      columns: [],
      filterFields: null,
    };
  },
  watch: { },
  async created() {
    await this.refreshData();
    this.filterFields = KycFilterFields();
  },
  computed: {
    ...mapGetters('data', ['allOrganizations']),
  },
  methods: {
    ...mapActions('kyc', ['refreshData']),
    openColumnsModal() {
      this.$refs['columns-modal'].showModal();
    },
    onKycSelected(selectedId) {
      // console.log("onKycSelected", selectedId)
      this.$refs['kyc-modal'].showModal(selectedId);
    },
  }
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}
</style>
